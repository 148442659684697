import React from 'react';
import { HiArrowNarrowRight, HiArrowNarrowLeft } from '@meronex/icons/hi/';
import { Link as GatsbyLink } from 'gatsby';
import { graphql, PageProps } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Box,
  Text,
  Divider,
  Stack,
  HStack,
  Button,
  useColorModeValue
} from '@chakra-ui/react';

import ShowMarkdown from '../components/shared/ShowMarkdown';

import Layout from '../components/layout';
import { BlogAuthor } from '../components/blog-posts-section/BlogAuthor';
import { BlogPageMedia } from '../components/shared/BlogPageMedia';
import { BlogMeta } from '../components/blog-posts-section/BlogMeta';
import SEO from '../components/seo/seo';
import { ISiteMetadata } from '../hooks/useSiteMetadata';
import ComponentMatch from '../components/component-match/component-match';
import { ICardDataQuery } from '../components/gallery-section/gallery-section';
import SideNavigation from '../components/side-navigation/side-navigation';
import BlogSidebar from '../components/blog-sidebar/blog-sidebar';
import MainContainer from '../components/container/container';

const UNPAD = {
  mx: {
    base: -8,
    lg: -4
  }
};

interface BlogPostQueryProps extends ISiteMetadata {
  contentfulBlogPost: {
    id: string;
    slug: string;
    title?: string;
    metaTitle?: string;
    metaDescription?: string;
    estReadTime: number;
    createdAt: string;
    metaImage?: {
      gatsbyImageData: IGatsbyImageData;
      title: string;
      description: string;
    };
    author: {
      name: string;
      bio: {
        bio: string;
      };
      title: string;
      image: {
        gatsbyImageData: IGatsbyImageData;
        title: string;
        description: string;
      };
    };
    sections: any;
    sideNavigation: any;
    noIndexNoFollow: boolean;
    galleryWeight: number;
  };
  allContentfulBlogSidebar: {
    nodes: Array<{
      header: string;
      contents?: {
        contents: string;
      };
      cta: any;
    }>;
  };
  galleryNodes: ICardDataQuery;
}

const BlogPost = ({
  data: {
    contentfulBlogPost: {
      id,
      slug,
      title,
      metaTitle,
      metaDescription,
      noIndexNoFollow,
      estReadTime,
      createdAt,
      metaImage,
      author,
      sections,
      sideNavigation
    },
    allContentfulBlogSidebar: sidebars,
    galleryNodes: { edges: galleryNodes }
  },
  pageContext,
  path
}: PageProps<BlogPostQueryProps>) => {
  return (
    <Layout isShowFooter={true}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage?.gatsbyImageData?.images?.fallback.src}
        pathname={path}
        lang={'en'}
        noIndexNoFollow={noIndexNoFollow}
      />

      <Box as="main">
        <Box
          bg={useColorModeValue('background50', 'blue.400')}
          borderBottomWidth={1}
          borderBottomColor={useColorModeValue('background100', 'brandSubtle')}
          mb={4}
        >
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            mx="auto"
            justifyContent="space-between"
            maxWidth="7xl"
            px={{
              base: 8,
              lg: 4
            }}
            py={4}
          >
            <HStack spacing="3">
              <Button
                as={GatsbyLink}
                to="/blog"
                variant="ghost"
                size="sm"
                className="group"
              >
                <HStack as="dt">
                  <Box
                    fontSize="md"
                    as={HiArrowNarrowLeft}
                    transition="all 0.2s"
                    _groupHover={{
                      color: useColorModeValue('sec.600', 'inherit'),
                      transform: 'translateX(2px)'
                    }}
                  />
                  <Text color={useColorModeValue('gray.900', 'white')}>
                    Back to blog
                  </Text>
                </HStack>
              </Button>
              {/* <Text fontSize="sm">{`Blog > ${metaTitle}`}</Text> */}
            </HStack>
            <HStack spacing="3" display={{ base: 'none', lg: 'inherit' }}>
              <Button
                as={GatsbyLink}
                to="/docs"
                variant="ghost"
                size="sm"
                className="group"
              >
                <HStack as="dt">
                  <Text color={useColorModeValue('gray.900', 'white')}>
                    Training
                  </Text>
                  <Box
                    fontSize="md"
                    as={HiArrowNarrowRight}
                    transition="all 0.2s"
                    _groupHover={{
                      color: useColorModeValue('sec.600', 'inherit'),
                      transform: 'translateX(2px)'
                    }}
                  />
                </HStack>
              </Button>

              <Button
                as="a"
                href="https://www.youtube.com/channel/UCOCC_XWXvT-z3WZZ9RDigGg/featured"
                variant="ghost"
                size="sm"
                className="group"
              >
                <HStack as="dt">
                  <Text color={useColorModeValue('gray.900', 'white')}>
                    Video Libary
                  </Text>
                  <Box
                    fontSize="md"
                    as={HiArrowNarrowRight}
                    transition="all 0.2s"
                    _groupHover={{
                      color: useColorModeValue('sec.600', 'inherit'),
                      transform: 'translateX(2px)'
                    }}
                  />
                </HStack>
              </Button>
            </HStack>
          </Stack>
        </Box>

        <MainContainer>
          <Box
            w="full"
            sx={{
              display: ['block', 'block', 'block', 'grid'],
              gridAutoFlow: 'column',
              gridTemplateColumns: 'auto 2fr 1fr'
            }}
          >
            <Box>
              {sideNavigation && <SideNavigation data={sideNavigation} />}
            </Box>

            <Box justifySelf="center" w="full" overflow="hidden">
              <Stack spacing={8}>
                <Box borderRadius="4" overflow="hidden">
                  <BlogPageMedia image={metaImage} />
                </Box>
                <Stack spacing={4}>
                  <BlogMeta
                    type={'article'}
                    estReadTime={estReadTime}
                    createdAt={createdAt}
                  />

                  <Stack spacing={2}>
                    <Text textStyle="header1">{metaTitle}</Text>
                    <BlogAuthor
                      name={author.name}
                      image={author.image}
                      role={author.title}
                    />
                  </Stack>
                  <Divider></Divider>
                </Stack>
              </Stack>

              <Box mt={4}>
                {sections.map((section, idx) => (
                  <Box key={`${section.id}-${idx}`} {...UNPAD}>
                    <ComponentMatch
                      data={section}
                      galleryNodes={galleryNodes}
                    />
                  </Box>
                ))}
              </Box>

              {author?.bio && (
                <Box mt={4} mb={6} borderTopWidth={1} borderTopStyle="solid">
                  <Box py={3}>
                    <Text textStyle="accentHeader" mb={0}>
                      About the author:
                    </Text>
                    <ShowMarkdown markdown={author?.bio.bio}></ShowMarkdown>
                  </Box>
                </Box>
              )}
            </Box>

            <Box
              display={{ base: 'none', lg: 'block' }}
              ml={{ base: 'none', lg: '8' }}
            >
              <BlogSidebar
                header={sidebars.nodes[1].header}
                contents={sidebars.nodes[1].contents.contents}
                links={sidebars.nodes[1].cta}
              />
            </Box>
          </Box>
        </MainContainer>
      </Box>
    </Layout>
  );
};

export const blogPostQuery = graphql`
  query BlogPostQuery($slug: String!, $galleryCategories: [String]!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      metaTitle
      metaDescription
      estReadTime
      createdAt
      metaImage {
        title
        description
        gatsbyImageData(width: 1400, layout: FIXED)
      }
      noIndexNoFollow
      author {
        title
        name
        bio {
          bio
        }
        image {
          title
          description
          gatsbyImageData(height: 48, quality: 100)
        }
      }
      sections {
        __typename
        ... on Node {
          id
        }
        ...BasicSection
        ...CalendlySection
        ...ContactSection
        ...FaqSection
        ...FeatureGridSection
        ...GallerySection
        ...HeroSection
        ...LogoSection
        ...MapSection
        ...PricingSection
        ...ProblemAndSolutionSection
        ...SideBySideSection
        ...StatsSection
        ...TestimonialSection
        ...TextSection
        ...TextColumnsSection
        ...UseCasesSection
      }
      sideNavigation {
        ...SideNavigation
      }
    }
    galleryNodes: allContentfulBlogPost(
      filter: {
        isDummyPage: { ne: true }
        galleryCategories: { elemMatch: { name: { in: $galleryCategories } } }
      }
      sort: { fields: [galleryWeight], order: ASC }
    ) {
      edges {
        node {
          id
          isDummyPage
          slug
          metaTitle
          metaDescription
          metaImage {
            title
            description
            gatsbyImageData(width: 1400, layout: FULL_WIDTH)
          }
          galleryCategories {
            name
          }
        }
      }
    }
    allContentfulBlogSidebar(
      limit: 2
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        id
        header
        contents {
          contents
        }
        cta {
          ...Links
        }
      }
    }
  }
`;

export default BlogPost;
