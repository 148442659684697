import { Box, Link } from '@chakra-ui/react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

interface IBlogMediaProps {
  image: {
    gatsbyImageData: IGatsbyImageData;
    title: string;
    description: string;
  };
}

export const BlogPageMedia = (props: IBlogMediaProps) => {
  const { image } = props;
  return (
    <Box
      pos="relative"
      h={{ base: '20rem', lg: '24rem' }}
      overflow="hidden"
      rounded="md"
    >
      <Box position="absolute" top={0} left={0} right={0} bottom={0}>
        <GatsbyImage
          objectFit="cover"
          style={{
            display: 'block',
            height: '100%',
            width: '100%'
          }}
          alt="Blog media"
          image={image.gatsbyImageData}
        />
      </Box>
    </Box>
  );
};
