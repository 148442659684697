import React from 'react';
import { Divider, Flex, Stack, Text } from '@chakra-ui/react';

import ShowMarkdown from '../shared/ShowMarkdown';

import LinkButton, { LinkButtonProps } from '../link-button/link-button';

type Props = {
  header: string;
  contents: string;
  links?: LinkButtonProps[];
};

const BlogSidebar = ({ header, contents, links }: Props) => {
  return (
    <Stack
      spacing={6}
      minWidth="8rem"
      maxWidth="400px"
      maxHeight={['200px', '100%']}
      overflow="auto"
      py={4}
      sx={{
        top: 20,
        position: 'sticky'
      }}
      p={4}
      pb={2}
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="4px"
    >
      <Stack spacing={2}>
        <Text textStyle="subheader">{header}</Text>
        <Divider></Divider>
        <ShowMarkdown markdown={contents}></ShowMarkdown>
      </Stack>
      {links?.length > 0 && (
        <Flex alignItems="center">
          {links.map((link, idx) => (
            <LinkButton
              key={`${link.id}-${idx}`}
              {...link}
              {...(links.length > 1 && links.length !== idx + 1 && { mr: 4 })}
            />
          ))}
        </Flex>
      )}
    </Stack>
  );
};

export default BlogSidebar;
